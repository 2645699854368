import React from 'react';
import { Table, TableHeader, TableHeaderCell, TableRow, TableBody, TableCell, Segment } from 'semantic-ui-react';
import IRacingIcon from './IRacingIcon';
import Position from './Position';

function LicenceResult({ licence, category, results }) {

    var licenceColorLookup = {
        "Rookie": "red",
        "Class D": "orange",
        "Class C": "yellow",
        "Class B": "green",
        "Class A": "blue",
    };

    var categoryLookup = {
        "road": "Road",
        "formula_car": "FormulaCar",
        "sports_car": "SportsCar",
        "oval": "Oval",
        "dirt_road": "DirtRoad",
        "dirt_oval": "DirtOval"
    };

    var backgroundLookup = {
        1: "#FFF8E5",
        2: "#F0F0F0",
        3: "#FFEFE5",
        0: "transparent"
    }

    function getBackground(position) {
        if (backgroundLookup.hasOwnProperty(position)) {
            return backgroundLookup[position];
        }
        else {
            return backgroundLookup[0];
        }
    }

    const licenceColor = licenceColorLookup[licence];

    var filtered = results.filter(function (item) { return item.licence === licence && item.isDuncathlon && ((category === 'Rookie' && item.licence === 'Rookie') || category === 'Overall' || category === undefined || (category === 'Road' && (categoryLookup[item.category] === 'FormulaCar' || categoryLookup[item.category] === 'SportsCar' || categoryLookup[item.category] === 'Road')) || categoryLookup[item.category] === category) });

    if (filtered.length === 0) return (<></>);

    return (
        <>
            <Segment compact inverted color={licenceColor} style={{ "margin-bottom": "-2px", "margin-left": "2px", "padding": "0.7em", "border-bottom-left-radius": "0", "border-bottom-right-radius": "0" }}>{licence}</Segment>
            <Table celled color={licenceColor} style={{ "margin-top": "0px" }}>
                <TableHeader>
                    <TableRow>
                        <TableHeaderCell width={1}>&nbsp;</TableHeaderCell>
                        <TableHeaderCell width={4}>Series</TableHeaderCell>
                        <TableHeaderCell width={4}>Class</TableHeaderCell>
                        <TableHeaderCell width={1} textAlign='center'>All Time</TableHeaderCell>
                        <TableHeaderCell width={1} textAlign='center'>Current Season</TableHeaderCell>
                    </TableRow>
                </TableHeader>

                <TableBody>
                    {filtered.map((series) => {
                        var isFirst = true;
                        return series.classes.map((c) => {

                            var thisIsFirst = isFirst;
                            isFirst = false;

                            return (
                                <TableRow>

                                    {thisIsFirst ?
                                        <>
                                            <TableCell textAlign='center' rowSpan={series.classes.length}>
                                                <IRacingIcon icon={categoryLookup[series.category]} />
                                            </TableCell>
                                            <TableCell rowSpan={series.classes.length}>
                                                {series.name}
                                            </TableCell>
                                    <TableCell>
                                        {c.name}
                                    </TableCell>
                                        </>
                                        :

                                        <TableCell style={{"border-left": "1px solid rgba(34,36,38,.1)"}}>
                                        {c.name}
                                    </TableCell>


                                    }

                                    {c.bestPosition !== null ?
                                        <TableCell textAlign='center' selectable style={{ "background-color": getBackground(c.bestPosition) }}>
                                            <a href={`https://members-ng.iracing.com/racing/web/results-stats/results?subsessionid=${c.bestPositionSubsessionId}`} target="_blank" rel='noreferrer'>
                                                <Position value={c.bestPosition} />
                                            </a>
                                        </TableCell>
                                        : <TableCell textAlign='center'>-</TableCell>
                                    }

                                    {c.currentBestPosition !== null ?
                                        <TableCell textAlign='center' selectable style={{ "background-color": getBackground(c.currentBestPosition) }}>
                                            <a href={`https://members-ng.iracing.com/racing/web/results-stats/results?subsessionid=${c.currentBestPositionSubsessionId}`} target="_blank" rel='noreferrer'>
                                                <Position value={c.currentBestPosition} />
                                            </a>
                                        </TableCell>
                                        : <TableCell textAlign='center'>-</TableCell>
                                    }

                                </TableRow>
                            );
                        });
                    })}
                </TableBody>
            </Table>
        </>
    );
}

export default LicenceResult;