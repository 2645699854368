import React, { useEffect, useState } from 'react';
import { Table, TableHeader, TableHeaderCell, TableRow, TableBody, TableCell, Header, Popup } from 'semantic-ui-react';
import { Link, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Position from './Position';

function Hosted() {


    var countryLookup = {
        "Australia": "au",
        "Austria": "at",
        "Belgium": "be",
        "Brazil": "br",
        "Canada": "ca",
        "England": "gb uk",
        "France": "fr",
        "Germany": "de",
        "Hungary": "hu",
        "Italy": "it",
        "Japan": "jp",
        "Netherlands": "nl",
        "Norway": "no",
        "Portugal": "pt",
        "Scotland": "gb uk",
        "Spain": "es",
        "USA": "us"
    }

    const { name } = useParams();

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {


        const fetchData = async () => {
            try {

                const response = await fetch(`https://challenge-api.duncanwatts.com/api/Hosted/${name}`);
                if (!response.ok) {
                    throw new Error('Network response was not ok.');
                }
                const data = await response.json();
                setData(data);
                setLoading(false);
            } catch (error) {
                setError(error.message);
                setLoading(false);
            }
        };
        fetchData();
    }, [name]);

    function getCountry(countryName) {
        return countryLookup.hasOwnProperty(countryName) ? countryLookup[countryName] : '';
    }

    function getSuffix(number) {

        if (Math.floor(number / 10) % 10 === 1)
            return "th";
        const remainder = number % 10;
        switch (remainder) {
            case 1:
                return "st";
            case 2:
                return "nd";
            case 3:
                return "rd";
            default:
                return "th";
        }
    }

    function formatDate(input) {
        var date = new Date(input);
        return `${date.getDate()}${getSuffix(date.getDate())} ${new Intl.DateTimeFormat([], { month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit'}).format(date).replace(',','')}`;
    }

    return (
        <>

            <Helmet>
                <title>Hosted | Series Challenge</title>
            </Helmet>

            {loading ? (

                <p>Loading...</p>

            ) : error ? (
                <p>Error: {error}</p>
            ) :
                (                    
                    <>

                        <Helmet>
                            <title>{data.name} | Series Challenge</title>
                        </Helmet>

                        <Header size='huge'>{data.name}</Header>
                        <Header size='large'>Calendar</Header>
                        <Table definition size='large'>
                            <TableHeader>
                                <TableRow>
                                    <TableHeaderCell />
                                    <TableHeaderCell>Date (UTC)</TableHeaderCell>
                                    <TableHeaderCell>Track</TableHeaderCell>
                                    {data.classes.map(hostedClass => 
                                    <TableHeaderCell><i class="trophy icon yellow"></i> {hostedClass.name}</TableHeaderCell>    
                                    )}
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {data.rounds.map(round =>
                                    <>
                                        <TableRow>
                                            <TableCell width={1} textAlign='center'><i class={`${getCountry(round.location)} flag`}></i></TableCell>
                                            <TableCell width={3}>{formatDate(round.dateTime)}</TableCell>
                                            <TableCell>{round.track}{round.layout !== null ? ` - ${round.layout}` : ''}</TableCell>
                                            {data.classes.map(hostedClass =>                                             (() => {
                                                var winningEntry = data.entries.find(e => e.results.some(r => r.roundNumber === round.roundNumber && r.overallPosition === 1 && e.class === hostedClass.name));
                                                return winningEntry != null
                                                    ? <TableCell width={4} selectable><Link to={`/Driver/${winningEntry.driverId}`}>{winningEntry.name}</Link></TableCell>
                                                    : <TableCell width={4}>-</TableCell>;
                                            })())}
                                        </TableRow>
                                    </>
                                )}
                            </TableBody>
                        </Table>

                        {data.classes.map(hostedClass => 
                            <>
                        <Header size='large'>{hostedClass.name}</Header>


                        <Table size='large' compact='very' definition>
                            <TableHeader>
                                <TableRow verticalAlign='bottom'>
                                    <TableHeaderCell width={5} colSpan={2} />
                                    {data.rounds.map(round => <TableHeaderCell width={1} textAlign='center' style={{ "word-wrap": "break-word" }}><div><span>{round.trackShort}</span></div></TableHeaderCell>)}
                                    <TableHeaderCell width={1} rowSpan={2}><div><span>Points</span></div></TableHeaderCell>
                                </TableRow>
                                <TableRow>
                                    <TableHeaderCell colSpan={2} />
                                    {data.rounds.map(round => <TableHeaderCell width={1} textAlign='center'><i class={`${getCountry(round.location)} flag`}></i></TableHeaderCell>)}
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {data.entries.filter(result => result.class === hostedClass.name).map(result =>
                                    <TableRow>
                                        <TableCell textAlign='center'><Position value={result.position} /></TableCell>
                                        <TableCell selectable width={4} style={{ 'border-right-width': '0.8px', 'border-right-color': 'rgba(34,36,38,0.1)', 'border-right-style': 'solid' }}><Link to={`/Driver/${result.driverId}`}>{result.name}</Link></TableCell>
                                        {data.rounds.map(round => (() => {
                                            var roundResult = result.results.find(r => r.roundNumber === round.roundNumber);
                                            if (roundResult != null) {                                                
                                                var diff = roundResult.finishPosition - roundResult.startPosition;
                                                var absDiff = Math.abs(diff);

                                                return <Popup size='large' trigger={<TableCell selectable textAlign='center' style={{
                                                    "background-color": (() => {
                                                        if (!roundResult.isCounted) return "transparent";
                                                        if (roundResult.overallPosition === 1) return "#FFF8E5";
                                                        if (roundResult.overallPosition === 2) return "#F0F0F0";
                                                        if (roundResult.overallPosition === 3) return "#FFEFE5";
                                                        if (roundResult.points > 0) return "#e5f9e7";
                                                        return "transparent";
                                                    })()
                                                }}><a href={`https://members-ng.iracing.com/web/racing/results-stats/results?subsessionid=${roundResult.subsessionId}`} target="_blank" rel='noreferrer'><Position value={roundResult?.overallPosition} /></a></TableCell>}>
                                                    <div class='content'>Started {roundResult.startPosition}{getSuffix(roundResult.startPosition)}</div>
                                                    <div class='content'>Finished {roundResult.finishPosition}{getSuffix(roundResult.finishPosition)}</div>
                                                    <div class='content'><i class={`large icon ${diff > 0 ? 'angle down red' : diff < 0 ? 'angle up green' : 'angle right'}`}></i>{absDiff} place{absDiff === 1 ? "" : "s"}</div>
                                                    <div class='content'>{roundResult.points} points</div>
                                                </Popup>;


                                            }
                                            else {
                                                return <TableCell width={1} textAlign='center'>-</TableCell>;
                                            }
                                        })())}
                                        <TableCell textAlign='center' className='last' style={{ 'border-left-width': '0.8px', 'border-left-color': 'rgba(34,36,38,0.1)', 'border-left-style': 'solid' }}>{result.points}</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>


                        </>
                        )}

                    </>
                )
            }

        </>
    );
}

export default Hosted;